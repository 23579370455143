import React from "react";
import styled from "styled-components";
import { Input, InputWrapper, Underline } from "./Form/Form";
import mailIcon from "../static/images/icons/icon_email_dark.svg"
import { StyledButton } from "./PageHeader/PageHeader";
import { colors } from "../style/style";

const NoCarriersWrapper = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 95px;
  @media (max-width: 1200px) {
    margin-left: calc(100vw - 480px);
  }
  @media (max-width: 560px) {
    max-width: 315px;
  }
  @media (max-width: 480px) {
    margin: 0;
  }
`
const Message = styled.h3`
  max-width: 220px;
  margin-bottom: 30px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
  color: #8B888E;
`
const NoCarriersInput = styled(Input)`
  color: ${ colors.dark };
  border-bottom-color: rgba(42, 32, 52, .4);
`
const Button = styled(StyledButton)`
  width: 168px;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  @media(max-width: 560px) {
    width: 141px;
  }
`
const NoCarriers = () => {
    return (
        <NoCarriersWrapper>
            <Message>
                At this moment we have no carriers in this state.
            </Message>
            <InputWrapper>
                <NoCarriersInput icon={ mailIcon } placeholder={ "Email" }/>
                <Underline/>
            </InputWrapper>
            <Button>Notify me</Button>
        </NoCarriersWrapper>
    )
}

export default NoCarriers
