import React, {useEffect, useState} from "react";
import {graphql} from 'gatsby';
import {HelmetDatoCms} from 'gatsby-source-datocms';
import styled from "styled-components";
import {motion} from "framer-motion";
import SwiperCore, {A11y, Mousewheel, Navigation, Pagination, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {fadeInLeft, fadeInUp, formFadeInUp} from "../style/motion-variants";

import {colors} from "../style/style";
import 'swiper/swiper-bundle.min.css';

import useWindowDimensions from "../utils/hooks/useWindowWidth";
import {swiperScrollOptions} from "./index";
import {SubTitle} from "../components/PageParts";
import Map from "../components/Map/Map";
import PageHeader from "../components/PageHeader/PageHeader";
import PageSection from "../components/PageSection";
import SlideInElement from "../components/SlideInElement/SlideInElement";
import SearchBar from "../components/SearchBar";
import NoCarriers from "../components/NoCarriers";
import Layout from '../components/layout';
import SlideFooter from "../components/SlideFooter";

import bgElement from "../static/images/bg-element-dark.svg"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel])

const SwiperContainer = styled(Swiper)`
  width: 540px;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    padding: 0 40px 50px;
  }

  .swiper-button-prev {
    left: -8px;
    background: url("data:image/svg+xml,%3Csvg width='13' height='25' viewBox='0 0 13 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 24L2 12.75L12 1.5' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A") center center no-repeat;
  }

  .swiper-button-next {
    right: -8px;
    background: url("data:image/svg+xml,%3Csvg width='13' height='25' viewBox='0 0 13 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L11 12.25L1 23.5' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A") center center no-repeat;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 40%;
    transform: translateY(-50%);
    outline: none;
    margin: 0;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    cursor: auto;
    pointer-events: none;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    display: none;
  }

  .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
    display: inline-block;
    opacity: 1;
    background: ${colors.disabledPagination};
    border-radius: 50%;
  }

  .swiper-pagination-bullet-active {
    background: ${colors.orange};
  }

  @media (max-width: 1200px) {
    margin: 0 0 0 calc(100vw - 700px);
  }

  @media (max-width: 900px) {
    margin-left: calc(100vw - 550px);
    width: 470px;
  }

  @media (max-width: 560px) {
    margin: 0 auto;
    width: 331px;

    .swiper-slide {
      padding: 0 30px 40px;
    }
  }
`
const CarrierLogo = styled.div`
  width: 120px;
  height: 50px;
  margin: 0;
  background: url("${props => props.src}") center / contain no-repeat;
  filter: grayscale(1);
  transition: filter .3s ease;
  cursor: pointer;

  &:hover {
    filter: grayscale(0);
  }

  @media (max-width: 900px) {
    width: 110px;
    height: 44px;
  }

  @media (max-width: 560px) {
    width: 73px;
    height: 30px;
  }
`
const Wrapper = styled.div`
  position: relative;
  z-index: 1;

  @media all and (min-width: 1200px) {
    display: flex;
    align-items: center;
  }
`

const CarriersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 120px);
  grid-template-rows: repeat(3, 50px);
  gap: 50px;
  @media (max-width: 900px) {
    gap: 30px;
    grid-template-columns: repeat(3, 110px);
    grid-template-rows: repeat(3, 44px);
  }
  @media (max-width: 560px) {
    gap: 26px;
    grid-template-columns: repeat(3, 73px);
    grid-template-rows: repeat(3, 30px);
  }
`

export const carriersQuery = graphql`{
    seo: datoCmsSeo(slug: {eq: "carriers"}) {
        meta: seoMetaTags {
            tags
        }
    }
    allDatoCmsState {
        nodes {
            name
            id
            state
            carriers {
                logo {
                    url
                    alt
                }
                id
            }
        }
    }
}`;

const Carriers = (props) => {
    const {seo, allDatoCmsState: {nodes: states}} = props.data;
    const [index, setIndex] = useState(0);
    const [state, setState] = useState('tx');
    const [swiper, setSwiper] = useState(null);
    const { width } = useWindowDimensions();

    const carriersInSelectedState = states.find(s => s.state.toLowerCase() === state)?.carriers ?? [];
    const logoToGrid = (arr) => {
        const copy = [...arr]
        const result = [];
        const logosInSlide = width < 768 ? 12 : 9
        while (copy.length > 0) {
            result.push(copy.splice(0, logosInSlide))
        }
        return result
    }
    const gridSlides = logoToGrid(carriersInSelectedState);

    useEffect(() => {
        if (swiper && carriersInSelectedState.length > 1) swiper.slideTo(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    return (
        <Layout>
            <HelmetDatoCms seo={seo?.meta}/>
            <PageHeader color={index === 0 ? colors.dark : colors.light}/>

            <Swiper
                speed={500}
                mousewheel={swiperScrollOptions}
                autoHeight={true}
                direction={"vertical"}
                spaceBetween={100}
                slidesPerView={1}
                onSlideChange={(e) => setIndex(e.realIndex)}
                style={{height: '100vh'}}
                shortSwipes={false}
                breakpoints={{768: {spaceBetween: 200}}}
            >
                <SwiperSlide>
                    <PageSection>
                        <SlideInElement top={103} left={-369} bg={bgElement} variant={fadeInLeft}/>
                        <Wrapper>
                            <div style={{marginRight: "58px"}}>
                                <motion.div exit={{opacity: 0}} variants={fadeInUp} initial="initial" animate="animate">
                                    <SubTitle color={colors.dark} maxWidth={600}>Serving <span
                                        style={{whiteSpace: 'nowrap'}}>all 50 states</span></SubTitle>
                                </motion.div>
                                <div>
                                    {width >= 1200 && <Map state={state} setState={setState}/>}
                                </div>
                                {width <= 1199 && <SearchBar states={states} setState={setState} style={{opacity: width <= 1201 ? 1 : 0}}/>}
                            </div>
                            <motion.div exit={{opacity: 0}} variants={formFadeInUp} initial="initial" animate="animate">
                                {carriersInSelectedState.length < 1
                                    ? <NoCarriers/>
                                    : <SwiperContainer
                                        navigation={gridSlides.length > 1}
                                        onSwiper={setSwiper}
                                        pagination={{clickable: true}}
                                        slidesPerView={1}
                                        spaceBetween={50}
                                    >
                                        {gridSlides.map((grid, i) => (
                                            <SwiperSlide key={i}>
                                                <CarriersGrid>
                                                    {grid.map(({id, logo}) =>
                                                        <CarrierLogo key={id} src={logo.url} alt={logo.alt}/>)}
                                                </CarriersGrid>
                                            </SwiperSlide>
                                        ))}
                                    </SwiperContainer>
                                }
                            </motion.div>
                        </Wrapper>
                    </PageSection>
                </SwiperSlide>
                <SwiperSlide>
                    {({isActive}) => (
                        <SlideFooter isClipped={true} isActive={isActive}/>
                    )}
                </SwiperSlide>
            </Swiper>
        </Layout>
    )
}

export default Carriers;
